import { defineStore } from 'pinia';
import { useFetch } from '#app';
import { useAuthStore } from '~/stores/AuthStore';

export const useBundleStore = defineStore('bundle', {
  state: () => ({
    bundles: [],
    bundlesList: [],
    bundle: {}
  }),

  actions: {
    async fetchBundles(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await  useFetch(`${useRuntimeConfig().public.baseUrl}bundles`, {
          method: 'GET',
          headers: authStore.auth.headers,
          params: {
            page: payload ? payload.page : null,
            per_page: payload ? payload.per_page: null,
            category: payload ? payload.category: null,
          }
        });
        if (error.value) {
          checkStatusCode(error.value.data ? error.value.data.status_code : 401);
          return error.value.data;
        }
        this.bundles = data.value ? data.value.data : {};
        if (payload.page === 1) {
          
          this.bundlesList = data.value ? data.value.data.bundles : [];
        } else {
          this.bundlesList.push(...data.value.data.bundles);
        }
        return data.value ? data.value.data : {};
      } catch (err) {
        console.log(err);
     
        return err.response ? err.response.data : err;
      }
    },
    async fetchBundle(payload) {
      const authStore = useAuthStore();
      const { checkStatusCode } = useCheckRequestStatusCode();
      try {
        const { data, error } = await  useFetch(`${useRuntimeConfig().public.baseUrl}bundles/${payload.id}`, {
          method: 'GET',
          headers: authStore.auth.headers,
          params: {
            view: payload.view
          }
        });
        if (error.value) {
          checkStatusCode(error.value.data ? error.value.data.status_code : 401);
          return error.value.data;
        }
        this.bundle = data.value ? data.value.data : {};
        return data.value;
      } catch (err) {
        console.log(err);
     
        return err.response ? err.response.data : err;
      }
    },
    changeItemStatusAfterAddedToCart(bundleId) {
      const bundle = this.bundlesList.find(bundle => bundle.id === bundleId);
      if (bundle) {
        const bundleIndex = this.bundlesList.indexOf(bundle);
        this.bundlesList[bundleIndex].pay_button_status = false;
        this.bundlesList[bundleIndex].pay_button_title = "في السلّة";
      }
    }
  }

});
