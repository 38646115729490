export default {
  data() {
    return {};
  },
  methods: {
    getVideosLength(num) {
      if (num === 1) {
        return `${num} فديو واحد`;
      } else if (num === 2) {
        return `${num} فديوهين`;
      } else if (num > 2 && num <= 10) {
        return `${num} فديوهات`;
      } else if (num > 10) {
        return `${num} فديو`;
      } else {
        return ``;
      }
    },
    getProductsLength(num) {
      if (num === 1) {
        return `${num} منتج واحد`;
      } else if (num === 2) {
        return `${num} منتجان`;
      } else if (num > 2 && num <= 10) {
        return `${num} منتجات`;
      } else if (num > 10) {
        return `${num} منتج`;
      } else {
        return ``;
      }
    },
    getFavoriteType(type) {
      if (type.includes("Course")) {
        return "مادة ";
      } else if (type.includes("Article")) {
        return "مقال";
      } else if (type.includes("Ebook")) {
        return "كتاب إلكتروني";
      } else if (type.includes("Bundle")) {
        return "باقة";
      } else if (type.includes("Publication")) {
        return "إصدار";
      } else {
        return "إصدار";
      }
    },
    courseBtnName(course) {
      let isFree = true;
      if (course.price > 0) {
        isFree = false;
      } else if (course.old_price && course.old_price > 0) {
        isFree = false;
      }
      let value = "";
      if (isFree) {
        value = this.windowSize > 768 ? "اشتراك فوري" : "اشترك";
      } else {
        value = this.windowSize > 768 ? "اشترك الآن" : "اشترك";
      }
      return value;
    }
  }
};
